import React, { Component } from 'react'
import { ReactComponent as Logo } from '../assets/Logo.svg'
import { useSpring, animated, useTransition, config } from 'react-spring'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import ParticleCannon from '../components/ParticleCannon'

// import loadText from './TextScrambler'

import Homepage from './homepage'
import Quad from './quad'
import { ClientsPage } from './clients'

const Nav = () => {
  return (
    <div className="nav">
      <Link to="/clients" className="nav-link">
        Clients
      </Link>
      <Link to="/quad" className="nav-link">
        Capabilities
      </Link>
    </div>
  )
}

class App extends Component {
  componentDidMount() {
    console.log('Firing particle cannon')
  }

  emailHandler = () => {
    const str = `mailto:email-us@rs9.io?subject=I was just on your website and wanted to ask...&body=Thanks for taking the time to reach out, leave your enquiry below and we'll get back to you extremely fast:%0A%0A`
    return str
  }

  render() {
    return (
      <Router>
        <div className="App">
          <ParticleCannon>
            <div className="main-wrapper">
              <Link to="/">
                <div className="header">
                  <div className="logo">
                    <Logo className="logo-svg" />
                    <div className="logo-underline" />
                  </div>
                  {/* <Route path="/" exact component={Nav} /> */}
                  <Nav />
                </div>
              </Link>
              <div className="center-section">
                <Switch>
                  <Route path="/" exact component={Homepage} />
                  <Route path="/quad" exact component={Quad} />
                  <Route path="/clients" exact component={ClientsPage} />
                </Switch>
              </div>
              <a href={this.emailHandler()} className="contact-button">
                <div>contact</div>
              </a>
            </div>
            {/* <div className="homepage-btf">
              <div className="homepage__our-clients">
                <h1>Our Clients</h1>
              </div>
            </div> */}
          </ParticleCannon>
        </div>
      </Router>
    )
  }
}

export default App
