import React, { useState } from 'react'
import { render } from 'react-dom'
import { ReactComponent as Capabilities } from '../assets/Capabilities.svg'
import { ReactComponent as Contact } from '../assets/Contact.svg'
import { ReactComponent as Discriminators } from '../assets/Discriminators.svg'
import { ReactComponent as KeyPartnerships } from '../assets/KeyPartnerships.svg'
import { ReactComponent as Download } from '../assets/Download.svg'
import { useTrail, animated } from 'react-spring'
import { Link } from 'react-router-dom'

const text = {
  capabilityItems: [
    'Computer Science',
    'Machine Learning',
    'Artificial Intelligence',
    'Computer Vision',
    'Application Modernisation',
    'Data capture and visualisation',
    'Natural Language Processing',
    'Image Classification and processing',
    'Statistical regression',
    'Human Interface Design',
    'Space / Machine Learning intersectional technology',
    'Real time data analytics reporting and battlespace analysis',
    'Connected combat',
    'Distributed systems',
    'Sensors - data aggregation',
    'Smart Devices',
    'Blockchain cryptography',
    'Software integration - connected silos',
    'Autonomous software',
  ],
  discriminatorItems: [
    'Small company means we are much quicker to adapt than incumbents',
    'Projects have a quicker turn around due to reduced bureaucracy',
    'Lean methodologies used to reduce cost',
    'Primary focus is on reliability',
    'Secondary focus is on effectiveness',
    'Tertiary focus is on aesthetics - importantly for Human Interface Design',
  ],
  keyPartnerships: [
    'University of Canberra',
    'Borderforce representative',
    'Software and Development companies in Australia',
    'State Government representatives',
    'Collins Aerospace representatives',
  ],
}

const config = { mass: 3, tension: 2000, friction: 200 }

const RenderItems = (props) => {
  const { items, toggle } = props
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 'auto' : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })
  return (
    <ul>
      {trail.map(({ x, height, ...rest }, index) => (
        <animated.li
          key={items[index]}
          className="trails-text"
          style={{
            ...rest,
            transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
          }}
        >
          <animated.span className="quad-text-inner" style={{ height }}>
            {items[index]}
          </animated.span>
        </animated.li>
      ))}
    </ul>
  )
}

const Quad = () => {
  const [toggle, set] = useState(true)

  return (
    <div className="quad-wrapper">
      <div className="quad-left">
        <div className="quad-section">
          <Capabilities className="quad-title" onClick={() => set((state) => !state)} />
          <RenderItems items={text.capabilityItems} toggle={toggle} />
        </div>
      </div>
      <div className="quad-right">
        <div className="quad-section">
          <Discriminators className="quad-title" onClick={() => set((state) => !state)} />
          <RenderItems items={text.discriminatorItems} toggle={toggle} />
        </div>
        <div className="quad-section">
          <KeyPartnerships className="quad-title" onClick={() => set((state) => !state)} />
          <RenderItems items={text.keyPartnerships} toggle={toggle} />
        </div>

        <div className="quad-section">
          <Download className="quad-title" onClick={() => set((state) => !state)} />
          <Link to="./Quad.pdf" target="_blank" download className="quad-link">
            <div className="quad-download-btn">download as pdf</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Quad
