import React, { Component, useState, useEffect, Fragment } from 'react'
import { ReactComponent as Logo } from '../assets/Logo.svg'
import { animated, useTransition } from 'react-spring'
// import loadText from './TextScrambler'

class Homepage extends Component {
  render() {
    return (
      <div className="homepage">
        <div className="title">
          <div className="title-colour">
            Innovation is - <Animation />
          </div>
        </div>
        <div className="subtitle">
          RS9 uses the latest in technology to provide solutions to Defence and
          Government utilising AI, Machine Learning, innovation and end-to-end
          thinking
        </div>
      </div>
    )
  }
}

export default Homepage

const text = [
  { id: 1, text: 'bringing the future to the present' },
  { id: 2, text: 'a compelling vision' },
  { id: 3, text: 'courage in the face of uncertainty' },
  { id: 4, text: 'leading the charge into tomorrow' },
  { id: 5, text: 'the only way to win' },
]

const Animation = () => {
  const [index, changeIndex] = useState(0)
  let i = 1
  useEffect(() => {
    const interval = setInterval(() => {
      changeIndex(i++ % text.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const transition = useTransition(text[index], (item) => item.id, {
    from: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,100%,0)' },
  })
  return transition.map(({ item, props, key }) => {
    return (
      <Fragment>
        <animated.span
          style={{ ...props }}
          key={key}
          className="title-rotating"
        >
          <span className="title-invisible">Innovation is - </span> {item.text}{' '}
        </animated.span>
      </Fragment>
    )
  })
}
