import React from 'react'

import SMLogo from '../assets/Logos/SMlogo.png'
import CBcityLogo from '../assets/Logos/CBCity.png'
import CCULogo from '../assets/Logos/CCULogo.png'
import PitM3dLogo from '../assets/Logos/PitM3d.png'
import AgLogo from '../assets/Logos/aglogo.png'
import StrathLogo from '../assets/Logos/STrathlogo.png'

const ClientsPage = () => {
  return (
    <div className="clients">
      <div className=" title-colour clients-title">clients </div>

      <div className="clients-subtitle">A selection of client's we've worked with both past and present</div>

      <div className="clients-img-wrapper">
        <img src={SMLogo} alt="" className="clients__image" />
        <img src={CBcityLogo} alt="" className="clients__image" />
        <img src={CCULogo} alt="" className="clients__image" />
        <img src={PitM3dLogo} alt="" className="clients__image" />
        <img src={AgLogo} alt="" className="clients__image" />
        <img src={StrathLogo} alt="" className="clients__image" />
      </div>
    </div>
  )
}

export { ClientsPage }
